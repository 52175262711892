import React from 'react'
import styled, { css } from 'styled-components'
import { useTheme } from 'styled-components'
import { useI18n } from '@bestyled/primitives'

import { mediaqueries } from '../../styles/media'
import GridLayoutProvider from '../../styles/GridLayoutProvider'

import NavigationMenuAction from '../../components/Navigation.Menu.Action'
import Section from '../../components/Section'
// import Bio from '../components/Bio'
const HeroLanding: React.FC<any> = ({ context }) => {
  const theme: any = useTheme()
  const { HeroBg, HeroBackgroundPosition } = theme
  const { t } = useI18n()

  // ! MAKES subtile IT OPTIONAL WITH hidden VALUE
  let subHeader =
    t('site.hero.subTitle') !== 'site.hero.subTitle' && t('site.hero.subTitle') !== 'hidden'
      ? t('site.hero.subTitle')
      : null
  return (
    <BackgroundHero backgroundImage={HeroBg} HeroBackgroundPosition={HeroBackgroundPosition}>
      <Section relative id='Articles__Hero' className='container'>
        <div className='container'>
          <div className='row'>
            <HeadingContainer className='col-12'>
              <HeroHeading dangerouslySetInnerHTML={{ __html: t('site.hero.title') }} />
            </HeadingContainer>
            <SubheadingContainer className='col-12'>
              {subHeader && <SubheadingText>{subHeader}</SubheadingText>}
            </SubheadingContainer>

            <ActionMenuContainer className='col-12'>
              <NavigationMenuAction {...context} />
            </ActionMenuContainer>
          </div>
        </div>
      </Section>
    </BackgroundHero>
  )
}

export default HeroLanding

const SubheadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 50px;

  ${mediaqueries.desktop`
    justify-content: left;
    margin-bottom: 40px;
  `};

  ${mediaqueries.tablet`
    display: none;

  `};
`

const bgImageSet = css`
  background-image: -webkit-image-set(
    url(${(p) => p.theme.HeroBgTablet}) 1x,
    url(${(p) => p.theme.HeroBg}) 2x,
    url(${(p) => p.theme.HeroBgUrl}) 3x
  );
  background-image: image-set(
    url(${(p) => p.theme.HeroBgTablet}) 1x,
    url(${(p) => p.theme.HeroBg}) 2x,
    url(${(p) => p.theme.HeroBgUrl}) 3x
  );
`

const BackgroundHero = styled.header<{ backgroundImage: string; HeroBackgroundPosition: string }>`
  color: rgba(255, 255, 255, 0.85) !important;
  font-family: ${(p) => p.theme.fonts.header};
  font-size: ${(p) => p.theme.fonts.sizes[8]};
  font-weight: 300;
  line-height: 1.9;
  box-sizing: content-box;
  display: block;
  text-align: left !important;
  position: relative;

  padding: 90px 3.5vw 5rem !important;
  margin-top: -90px;
  z-index: 1;

  height: 100% !important;
  min-height: 90vh !important;
  background-image: url(${(p) => p.backgroundImage || p.theme.HeroBgUrl});

  background-image: -webkit-image-set(url(${(p) => p.theme.HeroBg}) 1x, url(${(p) => p.theme.HeroBgUrl}) 2x);
  background-image: image-set(url(${(p) => p.theme.HeroBg}) 1x, url(${(p) => p.theme.HeroBgUrl}) 2x);
  background-color: rgba(0, 0, 0, 0.5);
  background-position: ${(p) =>
    p.HeroBackgroundPosition ? p.HeroBackgroundPosition : p.theme.background.HeroBackgroundPosition};
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  ${(p) => (p.theme.backgroundHeroStyles ? p.theme.backgroundHeroStyles : '')}

  ${mediaqueries.tablet`
    height: 100% !important;
    background-image: url(${(p) => p.theme.HeroBgTablet});
    ${bgImageSet}
  `};
  ${mediaqueries.phone`
    height: 100% !important;
    background-image: url(${(p) => p.theme.HeroBgMobile});
    ${bgImageSet}
  `};

  > * {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
  }
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #191919;
    border-radius: inherit;
    box-sizing: border-box;
    opacity: 0.3;
  }
  .row > * {
    margin-left: 4vw;
  }
`

const SubheadingText = styled.h3`
  font-size: ${(p) => p.theme.fonts.sizes[6]}px;
  text-align: left !important;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: ${(p) => p.theme.fonts.header};
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  > a {
    text-align: left !important;
    font-family: ${(p) => p.theme.fonts.header};
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-weight: 400;
    box-sizing: border-box;
    text-decoration: none;
    background-color: transparent;
    transition: 0.2s linear;
    color: rgba(255, 255, 255, 0.8);
  }
`

const ActionMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;

  ${mediaqueries.desktop`
    margin-bottom: 80px;
    justify-content: space-between;
  `};

  ${mediaqueries.tablet`
    margin-bottom: 60px;
    justify-content: center;

  `};
  ${mediaqueries.phablet`
    margin-bottom: 60px;
    justify-content: center;

  `};

  ${mediaqueries.phone`
    justify-content: center;
  `};
`

const HeadingContainer = styled.div`
  margin: 100px 0 0 0;
  position: relative;
`

const HeroHeading = styled.h1`
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: ${(p) => p.theme.fonts.header};
  letter-spacing: 0.5px;
  font-size: ${(p) => p.theme.fonts.sizes[8]}px;
  line-height: 1.5;
  font-weight: ${(p) => p.theme.fonts.weights.heavy} !important;
  color: #fff;

  ${mediaqueries.desktop_up`
    font-size: ${(p) => p.theme.fonts.sizes[8] + 2}px;
    text-align: left;
  `}
  ${mediaqueries.tablet`
    text-align: center !important;
    font-size: ${(p) => p.theme.fonts.sizes[8] + 2}px;
  `}
  ${mediaqueries.phone`
    text-align: center !important;
    font-size: ${(p) => p.theme.fonts.sizes[7] + 2}px;
  `}
`
